var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded && _vm.client)?_c('div',[_c('div',{staticClass:"flix-form-group"},[_c('bookingDetails',{attrs:{"entry":_vm.client}})],1),_c('div',{staticClass:"flix-form-group"},[_c('router-link',{staticClass:"flix-btn flix-btn-info",attrs:{"to":{
        name: _vm.getEditLink(),
        params: {
          user: _vm.variables.user.md5_id,
          form: _vm.client.form,
          entryID: _vm.client.md5ID
        }
      },"href":"#","title":_vm.$tc('message.settings', 2)}},[_vm._v(_vm._s(_vm.$t('message.goTo', { name: _vm.$tc('message.customer', 1) + ' #' + _vm.client.booking_id })))])],1),_c('div',{staticClass:"flix-form-group"},[_c('a',{staticClass:"flix-html-a",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('message.close', { name: '' })))])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }