<template>
  <div v-if="loaded && client">
    <div class="flix-form-group">
      <bookingDetails :entry="client"></bookingDetails>
    </div>
    <div class="flix-form-group">
      <router-link
        :to="{
          name: getEditLink(),
          params: {
            user: variables.user.md5_id,
            form: client.form,
            entryID: client.md5ID
          }
        }"
        href="#"
        class="flix-btn flix-btn-info"
        :title="$tc('message.settings', 2)"
        >{{
          $t('message.goTo', {
            name: $tc('message.customer', 1) + ' #' + client.booking_id
          })
        }}</router-link
      >
    </div>
    <div class="flix-form-group">
      <a class="flix-html-a" href="#" @click.prevent="$emit('close')">{{
        $t('message.close', { name: '' })
      }}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'logEditLink',
  components: {
    bookingDetails() {
      return import('@/components/booking/data')
    }
  },
  props: {
    user: {
      type: [Number, String]
    }
  },
  data() {
    return {
      loaded: false,
      editLink: false,
      client: false,
      variables: this.$getUserVariables()
    }
  },
  computed: {},
  mounted() {
    this.getClient()
  },
  methods: {
    getEditLink() {
      var name = 'dashboardBookingDetails'
      if (this.variables.state === 'share') {
        name = 'sharesBookingDetails'
      }
      return name
    },
    getClient() {
      Object.keys(this.$store.getters.bookings).forEach((calendar) => {
        if (this.client) {
          return false
        }
        this.$store.getters.bookings[calendar].forEach((client) => {
          if (this.client) {
            return false
          }
          if (client.ID.toString() === this.user.toString()) {
            this.client = client
            this.loaded = true
            return false
          }
        })
      })
    }
  }
}
</script>
<style lang="sass" scoped></style>
